@import "app/styles/getty/Legacy.module.scss";

%assetButton {
  display: flex;
  align-items: center;
  height: 39px;
  color: $gi-white;
  background-color: rgba($gi-black, 0.6);
  border: 1px solid rgba($grey-70, 0.4);
  border-radius: 5px;
  cursor: pointer;
  transition: all $complex-duration ease;

  &:hover {
    color: $gi-black;
    background-color: $gi-white;
    text-decoration: none;
  }
}

%defaultButton {
  min-width: 39px;
  justify-content: center;
  margin-right: .5rem;
}

.tooltip {
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -0.65rem;
    left: 50%;
    z-index: $search-action-tooltip-z-index;

    height: 0;
    width: 0;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%, 4px, 0);
    transition: all $complex-duration ease;
  }

  &:before {
    border: {
      top: 10px solid rgba($grey-70, 0.4);
      right: 6px solid transparent;
      left: 6px solid transparent;
    }
  }

  &:after {
    border: {
      top: 9px solid $gi-white;
      right: 5px solid transparent;
      left: 5px solid transparent;
    }
  }

  &:hover {
    &:before,
    &:after,
    .container {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  .container {
    position: absolute;
    top: -2.925rem;
    left: 50%;

    padding: 0.625rem;

    font-size: 0.875rem;
    color: $gi-black;
    background-color: $gi-white;
    border: 1px solid rgba($grey-70, 0.4);
    border-radius: 0.25em;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%, 4px, 0);
    transition: all $complex-duration ease;

    &Left {
      @extend .container;

      left: 1.5em;

      @media #{$medium-up} {
        left: 2em;
      }

      @media #{$large-up} {
        left: 2.5em;
      }
    }

    span {
      padding-left: 0;
    }
  }
}
