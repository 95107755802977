@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "app/styles/getty/Tooltip.module.scss";


.actions {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: $action-icon-overlay-z-index;
  opacity: 1;
  width: 100%;
  background-color: $gi-white;
  border-top: 1px solid $grey-30;
  transition: opacity $complex-duration ease;

  .actionsContainer {
    display: grid;
    grid-template-columns: 38px 38px 38px 38px;
    display: ms-grid;
    -ms-grid-columns: 48px 48px 48px 48px;
    justify-content: space-between;

    transform: translate3d(0, 4px, 0);
    transition: transform $complex-duration ease;

    .galleryFixedAsset:hover .actions & {
      transform: translate3d(0, 0, 0);
    }

    .actionsItem {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      width: 38px;
      background: transparent;
      border: none;
      color: $grey-70;
      cursor: pointer;
      transition: color $complex-duration ease;

      &:hover {
        color: $gi-black;
      }

      &:last-child {
        -ms-grid-column: 2;
        grid-column-start: 2;
      }
    }

    .actionsList {
      display: grid;
      grid-template-columns: 38px 38px;
      grid-column-start: 3;
      grid-column-end: 5;
      display: ms-grid;
      -ms-grid-columns: 48px 48px;
      -ms-grid-column: 3;
      grid-gap: 10px;
    }

    .similarButton {
    }
  }
}

.actionTooltip {
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -0.65rem;
    left: 50%;
    z-index: $search-action-tooltip-z-index;

    height: 0;
    width: 0;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%, 4px, 0);
    transition: all $complex-duration ease;
  }

  &:before {
    border: {
      top: 10px solid rgba($grey-70, 0.4);
      right: 6px solid transparent;
      left: 6px solid transparent;
    }
  }

  &:after {
    border: {
      top: 9px solid $gi-white;
      right: 5px solid transparent;
      left: 5px solid transparent;
    }
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  .actionTooltipContainer {
    position: absolute;
    top: -2.925rem;
    left: 50%;

    padding: 0.625rem;

    font-size: 0.875rem;
    color: $gi-black;
    background-color: $gi-white;
    border: 1px solid rgba($grey-70, 0.4);
    border-radius: 0.25em;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%, 4px, 0);
    transition: all $complex-duration ease;

    &Left {
      left: 1.5em;

      @media #{$medium-up} {
        left: 2em;
      }

      @media #{$large-up} {
        left: 2.5em;
      }
    }

    span {
      padding-left: 0;
    }

    .actionTooltip:hover & {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.download {
  -ms-grid-column: 2;
}

.boardsButton {
  color: #666666;
  background: transparent;
  border: 0;

  &:hover {
    color: #080808;
    background: transparent;
  }
}
