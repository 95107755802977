@use '~@unisporkal/alliance-ui-theme/dist/styles/common/_index.scss' as
  commonStyles;
@use '~@unisporkal/alliance-ui-theme/dist/styles/getty/colors.module.scss' as
  gettyColors;

.newSortOptionsTooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
  right: 29px;
  height: auto;

  > div {
    > div {
      display: flex;
    }
  }
}

.tooltip {
  width: 201px;
  padding: 9px 16px;
  margin: 0 6px;
  z-index: 1;
  font-size: 14px;
  line-height: 16.8px;
  left: 0;
}

.newBadge {
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  background-color: commonStyles.$crimson;
  color: commonStyles.$true-white;
  margin-right: 3px;
}