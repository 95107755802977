@import "app/styles/getty/Legacy.module.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 2rem 1rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  height: 46px;
  width: 155px;
  font-size: .875rem;

  color: $gi-black;
  background-color: $gi-white;;
  border: 1px solid $gi-black;
  border-radius: 5px;

  &:focus, &:active {
    text-decoration: none;
  }
  
  &:hover {
    background-color: $gi-black;
    color: $gi-white;
    text-decoration: none;

    .icon {
      fill: $gi-white;
    }
  }

  @media #{$small-up} {
    font-size: 1rem;
  }
}

.nextButton {
  margin-left: 1rem;
  @media #{$medium-up} {
    margin-left: 50px;
  }
}

.previousButton {
  margin-right: 1rem;
  @media #{$medium-up} {
    margin-right: 50px;
  }
}

.buttonText {
  margin: 0 6px;
}

.icon {
  fill: $gi-black;
}

.previousIcon {
  transform: rotateY(180deg);
}

.lastPage {
  padding-left: 5px;
}

.input {
  width: 36px;
  height: 33px;
  font-size: 16px;
  text-align: center;
  margin: 8px;
  border: 1px solid $gi-black;
  background-color: $gi-white;

  &:focus {
    outline: none;
  }
}

.lastPage {
  padding-left: 5px;
}