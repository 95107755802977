@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Variables.module.scss";

.container {
  display: flex;
  cursor: pointer;
  border: none;

  color: white;
  background-color: #666;
  padding: 23px 34px;

  margin: 0 -32px;

  width: fill-available;

  @media #{$large-up} and #{$tall-screen-min} {
    display: none;
  }

  .textHolder {
    display: flex;
    align-items: center;
    margin-left: 0;
    fill: #fff;

    .text {
      font: 16px Lato;
      margin-left: 16px;
      display: flex;
    }
  }

  .iconHolder {
    margin-left: auto;

    .icon {
      fill: #fff;
    }
  }
}
