@import "app/styles/getty/Legacy.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support";
@import "app/styles/getty/Mixins.module.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.optionsContainer {
  position: relative;

  display: flex;
  height: calc(100% - 0.75rem);
}

.sections {
  position: relative;
  padding-bottom: 0.75em;

  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;

  width: 360px;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
}

.subheaders {
  padding: 0.75rem 0 0 0.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  font-size: 0.875rem;
  white-space: nowrap;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.subheader {
  @include buttonLink;
  display: block;
  color: $confirmed-color;

  & + & {
    margin-top: 0.25rem;
  }
}

.sectionHeader {
  margin: 0.75em 0;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  color: $grey-70;
  border-bottom: 1px solid $grey-30;
}

.option {
  color: $gi-black;
  font-size: .875rem;

  & + & {
    margin-top: 0.5rem;
  }
}

.checkbox {
  margin-right: 0.5rem;
  vertical-align: middle;
  cursor: pointer;

  &:not(:checked):hover {
    background-image: url(~images/checkmark-gray.svg);
    border-color: $gi-black;
  }
}

.checkboxLabel {
  vertical-align: middle;
  cursor: pointer;
}

.link {
  @include buttonLink;

  &:hover {
    color: $confirmed-color;;
    cursor: pointer;
    text-decoration: none;
  }
}

.date {
  color: #666;
  font-size: .75rem;
  margin-bottom: .25rem;
  margin-top: .75rem;
}
