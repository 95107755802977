@import "app/styles/getty/Legacy.module.scss";

.container {
  background-color: #FBFBFB;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin: 0 0 26px 0;
  padding: 0;
  overflow: hidden;

  @mixin baseOption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -1px;
    border-bottom: 1px solid #CCCCCC;
    transition: all 0.3s ease;
    padding: 0.75rem;
  }

  .option {
    @include baseOption;
  }

  .highlight {
    background-color: #f9f0bd;
    border-top: 1px solid #CCC;
  }

  .selected {
    @include baseOption;
    color: #FFFFFF;
    background-color: #666666;
  }

  .option:hover {
    .radio {
      border-color: $gi-black;

      &:not(:checked):before {
        background-color: $grey-50;
      }
    }
  }

  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: $gi-white;
    border: 1px solid $grey-50;
    border-radius: 100%;
    cursor: pointer;
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
    outline: none;

    margin-right: 1rem;

    &:before {
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin: {
        top: 2px;
        left: 2px;
      }

      border-radius: 100%;
      transition: all $complex-duration ease;
    }

    &:checked, &:hover {
      border-color: $gi-black;
    }
    &:checked:before {
      background-color: $gi-black;
    }
    &:not(:checked):hover:before {
      background-color: $grey-50;
    }
  }

  .label {
    display: flex;
    overflow: hidden;
    cursor: pointer;

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.875rem;
      text-wrap: wrap;
    }
  }

  @mixin baseLogo {
    height: 27px;
    width: 27px;
    color: #666;

    margin-left: auto;
    margin-right: 20px;
  }

  .logo {
    @include baseLogo;
  }

  .selectedLogo {
    @include baseLogo;
    color: #fff;
  }

  .tooltip {
    top: calc(100% - 60px);
    z-index: 5;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #a6a6;
    width: 170px;
    padding: 10px 16px;
    line-height: 16.8px;
    color: black;
    position: absolute;
    left: 145px;

    &::after {
      border-color: #a6a6;
    }
  }
}
