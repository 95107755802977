@import "app/styles/getty/Legacy.module.scss";

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 0 1rem;

  @media #{$medium-up} {
    padding: 0 2.25rem;
  }
}

.gridToggle {
  display: none;
  @media #{$medium-up} {
    display: block;
    min-width: 84px;
  }
}

.label {
  display: inline-block;
  height: 36px;
  width: 42px;
  background-color: $gi-white;
  padding: 9px 12px;
  border: 1px solid $grey-50;
  cursor: pointer;
}

.labelSelected {
  display: inline-block;
  height: 36px;
  width: 42px;
  background-color: $grey-70;
  padding: 9px 12px;
  border: 1px solid $grey-50;
  cursor: pointer;

  .icon {
    fill: $gi-white;
  }
}

.labelFixed {
  border-radius: 5px 0px 0px 5px;
  border-right: 0px;
}

.labelMosaic {
  border-radius: 0px 5px 5px 0px;
}
