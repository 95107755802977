@use '~@unisporkal/alliance-ui-theme/dist/styles/common/_index.scss' as
  commonStyles;
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "app/styles/getty/Zindex.module.scss";


.parentContainer {
  position: relative;
}

.tooltipContainer {
  font-size: 14px;
  position: fixed; 
  z-index: $filter-panel-z-index;
 
  @media #{$large-up} {
    position: absolute;
  }
  
  span {
    margin-right: 3px;
  }

  button {
    right: 5px;
  }
}

.tooltip {
  padding: 7px 16px;
}

.newBadge {
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  background-color: commonStyles.$crimson;
  color: white;
  margin-right: 3px;
}