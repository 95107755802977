@import "app/styles/getty/Legacy.module.scss";

.holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @mixin baseOption {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-basis: 48%;
    height: 47px;
    max-width: 48%;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    background-color: $light-grey;
    border: 1px solid $grey;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }

    .text {
      font-size: 0.875rem;
      color: $gi-black;
    
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .option {
    @include baseOption;

    &:hover {
      background-color: $gi-white;
      border-color: $gi-black;
    }
  }

  .selected {
    @include baseOption;
    background-color: $drk-grey;

    .text {
      color: $gi-white;
    }
  }
}