@import "app/styles/getty/Legacy.module.scss";

.holder {
  flex-wrap: wrap;
  justify-content: space-between;
}

.event {
  cursor: pointer;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  .date {
    font-size: 0.75rem;
    color: $grey-50;
    padding-bottom: 6px;
  }
  
  .text {
    font-size: 0.875rem;
    padding-bottom: .75rem;
    cursor: pointer;
    transition: color $complex-duration ease;
  
    &:hover {
      color: $confirmed-color;
      transition: none;
    }
  }
}