@import "app/styles/getty/Legacy.module.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 1;

  border-bottom: solid 1px $grey-40;
  background-color: $gi-white;

  @media #{$medium-up} {
    flex-direction: row;
  }
}

.outerContainer {
  width: 100%;
}

.assetTypeToggle {
  margin-top: 2em;
}
