@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "app/styles/getty/Variables.module.scss";

@keyframes slide-out {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@mixin baseContainer {
  display: flex;
  flex-direction: column;
  background-color: $gi-white;
  border-right: 1px solid $drk-grey;

  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;

  padding: 0 32px;

  @media #{$small-up} {
    width: 300px;
  }

  @media #{$large-up} {
    animation: $complex-duration linear forwards slide-out;
    animation-timing-function: ease;
    width: 300px;
    border-right: 1px solid $grey;
    background-color: $gi-white;
    z-index: 10;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
}

.simpleColumnPanelContainer {
  display: flex;
  flex-direction: row;
  width: 300px;
}

.simpleContainerOpen {
  @include baseContainer;
  position: fixed;
  left: 0;
  height: 100%;
  overflow: scroll;

  @media #{$large-up} {
    position: relative;
    overflow: hidden;
  }
}

.containerOpen {
  @include baseContainer;
  left: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: overlay;

  /* Hide scrollbar for */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: $med-grey white;

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    opacity: 1;
    background: $med-grey;
    border-radius: 5px;
  }

  @media #{$large-up} {
    position: relative;
    // Could not use scrollbar-gutter to prevent content jitter caused by
    // `scrollbar-width: none`. So instead, toggle the transparency via hover.
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar-thumb { // Safari
      opacity: 0;
      background: transparent;
    }

    &:hover {
      scrollbar-color: auto;

      // Safari
      &::-webkit-scrollbar-thumb {
        opacity: 1;
        background: $med-grey;
      }
    }
  }
}

.hideRefineButton {
  @media #{$large-up} and #{$tall-screen-max} {
    display: none;
  }
}

.columnPanelContainer {
  display: flex;
  flex-direction: row;
  width: 300px;

  @media #{$large-up} {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 97vh;
    position: sticky;
    top: 203px;
    transition: all $complex-duration ease;
  }

  @media #{$large-up} and #{$tall-screen-max} {
    top: 60px;
  }

  &.noTerms {
    @media #{$large-up} and #{$tall-screen-min} {
      top: 149px;
    }
  }

  &.columnPanelContainerDocked {
    @media #{$large-up} {
      top: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;

  display: none;
  height: 100vh;
  width: 100%;

  background-color: rgba($black, 0.5);

  @media #{$medium-up} {
    display: block;
  }

  @media #{$large-up} {
    display: none;
  }
}

.noAnimation {
  animation: none;
}
