@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Mixins.module.scss";

.container {
  display: flex;
  justify-content: center;
  background-color: $gi-white;
  height: 46px;
}

.banner {
  display: flex;
  align-items: center;
  margin: 0 .5rem;
  
  text-align: center;
}

.bannerMessage {
  padding: 1rem 0;
  font-size: .875rem;
}

.bannerIcon {
  margin-right: .5rem;
}

.bannerLink {
  @include buttonLink;
  color: $confirmed-color;
  padding: 0 5px;
}