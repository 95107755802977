@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-20;
  padding: {
    left: 2em;
    right: 2em;
  }

  position: absolute;
  height: 65px;
  width: 100%;
  border-bottom: solid 1px $grey-40;
  z-index: $gallery-batch-download-banner-z-index;
  box-shadow: 0 5px 5px rgba(8,8,8,0.2);
}

.containerSticky {
  position: fixed;
  top: 0px;

  border-bottom: none;
  backface-visibility: hidden;

  // removes jaggies on scroll
  -webkit-backface-visibility: hidden;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
}

.rightContainer {
  flex-direction: row;
  display: flex;
}

.bannerButton {
  display: flex;

  margin-right: 1.4rem;
  padding: {
    top: 0.7rem;
    right: 1.25rem;
    bottom: 0.7rem;
    left: 1.25rem;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    background-color: $gi-white;
    color: $gi-black;
  }

  &[disabled] {
    pointer-events: none;
  }
}

.bannerIcon {
  margin-right: 6px;
  height: 16px;
  width: 16px;
}

.bannerDivider {
  margin-right: 1.4rem;

  border-right: solid $grey-40 1px;
}

.bannerWarning {
  display: flex;
  align-items: center;

  font-size: 0.875rem;
}
