@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "app/styles/getty/Mixins.module.scss";

.fixedAsset {
  width: 195px;
  min-height: 380px;
  margin: {
    top: -1px;
    left: -1px;
  }
  padding: {
    right: 0.5em;
    left: 0.5em;
  }

  display: flex;
  flex-direction: column;

  background-color: $gi-white;
  border: 1px solid $grey-40;

  &Content {
    padding: 1em 0.5em;
  }

  &LicenseInfo {
    display: flex;
    align-items: center;
    color: $grey-70;
  }

  &Icon {
    height: 27px;
    width: 27px;
    margin-right: 0.5rem;
  }

  &VideoIcon {
    height: 1.25em;
    width: auto;
  }

  &LicenseText {
    display: flex;
    align-items: center;
    font-size: 0.875em;
  }

  &FilmDefinition {
    font-size: 0.875em;
  }

  &Badge {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0.25rem 0.5rem;

    font-size: 0.75em;
    color: $gi-black;
    background-color: $warning-color;
    text-align: center;

    &Exclusive {
      margin-right: 0.5rem;
    }
  }

  &People {}

  &Title {
    margin: {
      top: 0.75rem;
      bottom: 0.5rem;
    }

    font-size: 0.875em;
  }

  &List {
    font-size: 0.75em;
    color: $grey-70;
    line-height: 1.4;
  }

  &ListClickable {
    @include buttonLink;
    color: $confirmed-color;
  }

}
