@import "app/styles/getty/Tooltip.module.scss";

%cart {
  @extend %defaultButton;

  circle {
    opacity: 0;
    transition: opacity $complex-duration ease;
  }

  &.inCart {

    circle {
      opacity: 1;
    }
  }
}

.mosaic {
  @extend %assetButton;
  @extend %cart;
}

.fixed {
  @extend %cart;
  cursor: pointer;
}
