@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-left: 0;
  border-left: none;
  padding-left: 0;
}

.navigation {
  display: none;

  @media #{$medium-up} {
    display: block;
  }

  button {
    z-index: 1;
  }

  &Left,
  &Right {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 30px;
    height: 40px;
    background-color: $grey-20;
    outline: none;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    &:hover {
      background: $grey-30;
    }
    &:focus,
    &:active {
      border: none;
    }
  }

  &Left {
    left: -4px;
  }

  &Right {
    right: -4px;
    transform: rotate(180deg);
  }
}
