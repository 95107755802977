@import "app/styles/getty/Legacy.module.scss";

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-basis: 100%;
  min-width: 60%;
  padding: 0.67rem 20px 0.67rem 0;

  @media #{$medium-up} {
    min-width: 0%;
  }
}

.colorMoodsContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 100%;
  min-width: 0;
  padding: 0.67rem 20px 0.67rem 0;

  @media #{$medium-up} {
    justify-content: flex-end;
  }
}

.results {
  color: $black;
  font-size: .875rem;
  -webkit-font-smoothing: antialiased;
  margin: 0 16px 0 0;

}

.link {
  line-height: 1;
  display: flex;
  align-items: baseline;

  .icon {
    margin-right: 8px;
    fill: $purple;
  }

  a {
    cursor: pointer;
    color: $purple;
    &:hover {
      color: $purple-darker;
    }
  }
}
