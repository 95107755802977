@import 'app/styles/getty/Legacy.module.scss';
@import 'app/styles/getty/Mixins.module.scss';

.container {
  display: flex;
  align-items: center;
  height: 3.375rem;

  border: {
    top: solid 1px $grey-40;
    bottom: solid 1px $grey-40;
  }

  @media #{$medium-up} {
    height: 4rem;
    margin-left: auto;

    border: none;
  }

  overflow: hidden;

  .list {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: {
      left: 1.5rem;
      right: 1.5rem;
    }

    @media #{$medium-up} {
      overflow: hidden;
      padding-right: 2rem;
    }

    @mixin baseItem {
      flex-shrink: 0;
      .button {
        @include buttonLink;
        padding: {
          left: 1.5rem;
          right: 1.5rem;
          top: 0.4rem;
          bottom: 0.4rem;
        }

        color: $gi-black;
        border-radius: 5px;
        display: inline-block;

        &:hover {
          color: $confirmed-color;
          text-decoration: none;
        }
      }
    }

    .item {
      @include baseItem;
    }

    .selected {
      @include baseItem;
      color: $gi-white;
      background-color: $grey-70;

      border-radius: 5px;
      transition: background-color $complex-duration ease;

      &:hover {
        color: $gi-white;
      }

      .button {
        @include buttonLink;
        padding: {
          left: 1.5rem;
          right: 1.5rem;
          top: 0.4rem;
          bottom: 0.4rem;
        }

        color: $gi-white;
        border-radius: 5px;
        display: inline-block;

        &:hover {
          color: $gi-white;
          text-decoration: none;
        }
      }
    }
  }
}

.container:empty {
  display: none;
}
