@use '~@unisporkal/alliance-ui-theme/dist/styles/common/_index.scss' as
  commonStyles;
@use '~@unisporkal/alliance-ui-theme/dist/styles/getty/colors.module.scss' as
  gettyColors;
  @import "app/styles/getty/Legacy.module.scss";

.tooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  left: -28px;
  > div {
    > div {
      display: flex;
    }
  }
}

.tooltip {
  width: 201px;
  color: black;
  padding: 9px 16px;
  margin: 0 6px;
  z-index: 1;
  font-size: 14px;
  line-height: 16.8px;
  left: 33px;
  top: 25px;
  &:after {
    left: 34%;
  }
}

.newBadge {
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  background-color: commonStyles.$crimson;
  color: commonStyles.$true-white;
  margin-right: 3px;
}

@media #{$large-up} {
  .tooltipContainer {
    left: -25px;
  }

  .tooltip {
    left: 2px;
    top: 25px;
    &:after {
      left: 50%;
    }
  }
}