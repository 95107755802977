@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

$checkbox-size: 1.25em;

.checkbox-field__input {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid $grey-50;
  cursor: pointer;
  height: $checkbox-size;
  width: $checkbox-size;
  background: {
    color: transparent;
    repeat: no-repeat;
    position: center;
    size: 71% 71%;
  }

  &:focus {
    border-color: $gi-black;
    outline: none;
  }

  &:checked {
    background-image: url(~images/checkmark-black.svg);
    border-color: $gi-black;
  }

  &:not(:checked):hover {
    background-image: url(~images/checkmark-gray.svg);
    border-color: $gi-black;
  }
}
