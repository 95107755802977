@import "app/styles/getty/Legacy.module.scss";

@mixin baseHeader {
  -webkit-appearance: none; // Necessary for Safari 15 styles
  font-size: 1rem;
  margin: 24px 0px;
  .icon {
    fill: black;
  }
}

@mixin baseAccordionHeader {
  @include baseHeader;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: black;
  width: 100%;
}

@mixin baseContainer {
  border-bottom: 1px solid #E3E3E3;

  @media #{$large-up} {
    width: 100%;
  }
  button {
    background: none;
    border: none;
  }
  .header {
    @include baseHeader;
    .icon {
      display: none;
    }
  }

  .headerWithIcon {
    @include baseAccordionHeader;
  }

  .holder {
    margin-bottom: 14px;
  }
}

.container {
  @include baseContainer;
  position: relative;
  &:last-of-type {
    margin-bottom: 180px;
  }
}

.subContainer {
  @include baseContainer;
  padding: 0;
  margin-top: 20px;
  border-bottom: unset;

  .header, .headerWithIcon {
    font-size: 0.875rem;
  }
}
