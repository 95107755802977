@import "app/styles/getty/Legacy.module.scss";

@mixin baseContainer {
  display: flex;
  cursor: pointer;
  border: none;

  background-color: #fff;
  border-right: 1px solid #ccc;

  height: 54px;
  width: 90px;
  min-width: 90px;
  padding: 0 24px 0 10px;
  align-items: center;
  justify-content: center;

  @media #{$medium-up} {
    height: 64px;
  }

  @media #{$large-up} {
    width: 174px;
    padding-right: 40px;
    padding-left: 36px;
    align-items: unset;
    justify-content: unset;
  }

  .icon {
    fill: $purple;
  }

  &:hover {
    color: $gi-white;
    background-color: $grey-70;

    .icon {
      fill: $gi-white;
      transform: scalex(-1);
    }
  }

  .holder {
    display: flex;
    align-items: center;
    margin-left: 0;

    .text {
      display: none;
      margin-left: 1em;

      @media #{$large-up} {
        display: block;
      }
    }
  }

  .leftArrowShow {
    margin-left: auto;
  }

  .leftArrowHide {
    display: none;
  }
}

.containerShort {
  @include baseContainer;
  flex-shrink: 0;
  transition: width $complex-duration ease-in-out;
}

.containerShortDelay {
  @include baseContainer;
  transition: width $complex-duration ease-in-out;
  transition-delay: 0.5s;
}

.containerWide {
  @include baseContainer;

  color: $gi-white;
  background-color: $grey-70;

  .icon {
    fill: $gi-white
  }

  &:hover .icon {
    transform: none;
  }

  @media #{$large-up} {
    display: flex;
    align-items: center;
    min-width: 300px;
    width: 300px;
    padding-right: 36px;
    padding-left: 36px;
  }
}

.containerAppliedFilters {
  @include baseContainer;

  color: $gi-white;
  background-color: $purple;

  .icon {
    fill: $gi-white
  }

  &:hover {
    color: $gi-white;
    background-color: $purple;

    .icon {
      transform: scalex(-1);
    }
  }

  @media #{$large-up} {
    display: flex;
    align-items: center;
    min-width: 260px;
    width: 260px;
    padding-left: 32px;
    padding-right: 31px;
  }
}

.containerHidden {
  @include baseContainer;
  @media #{$large-up} {
    display: none;
    min-width: 0px;
    width: 0px;
    padding-right: 0;
    padding-left: 0;
  }
}
