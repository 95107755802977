@import "app/styles/getty/Legacy.module.scss";

.agreementDownload {
  background: transparent;
  border: none;
  padding-right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $confirmed-color;
  }

  &Disabled {
    color: $grey-50;
  }
  
  &Icon {
    height: 1em;
    width: 27px;
    margin-right: 0.5rem;
  }
}
