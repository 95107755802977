//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints.scss';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

@import './RelatedTerms.module.scss';

.relatedTerms {
  @media #{$medium-up} {
    overflow-x: hidden;
  } 
}