@import "app/styles/getty/Legacy.module.scss";

.modalLinks {
  margin-bottom: 24px;
}

.modalLink {
  display: block;
  cursor: pointer;
  font-size: .875rem;
  color: $purple;
  margin-bottom: .75rem;
}