@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Mixins.module.scss";

.container {
  display: flex;
  height: 3.375rem;
  position: relative;
  align-items: center;
  @media #{$medium-up} {
    height: 4rem;

    border-bottom: none;
  }

  .list {
    display: flex;
    align-items: center;
    height: 100%;
    padding: {
      right: 1.5rem;
      left: 1.5rem;
    }

    @media #{$medium-up} {
      padding: {
        right: 0;
        left: 2rem;
      }
    }

    .button {
      @include buttonLink;
      margin-right: 1.25rem;
      padding-bottom: 3px;
      
      &:hover {
        text-decoration: none;
        color: #6F43D6
      }
    }

    .selected {
      @include buttonLink;
      margin-right: 1.25rem;
      padding-bottom: 0;

      border-bottom: 3px solid $confirmed-color;

      &:hover {
        text-decoration: none;
        color: #6F43D6
      }
    }
  }
}