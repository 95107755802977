@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "app/styles/getty/Variables.module.scss";

$desktop-header-height: 138px;

.simpleHeaderSearchBar {
  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;

  @media #{$medium-up} {
    padding: 12px 2em;
    height: 86px;
  }
}

.headerSearchBar {
  background-color: $grey-20;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;

  @media #{$medium-up} and #{$tall-screen-min} {
    padding: 11px 2em;
    height: auto;
  }
}

.simpleHeaderSearchBarContainer {
  display: none;
  @media #{$medium-up} and #{$tall-screen-min} {
    display: block;
    padding: 12px 0 0 0;
    margin: 0;
  }
};

.placeholderHeaderSearchBar {
  @media #{$medium-up}, #{$tall-screen-min} {
    padding: 12px 2em;
    height: 85px;
  }
}

.searchBar {
  z-index: 11;
}

@mixin baseStyle {
  display: block;
  width: 100%;
  background: white;
  position: fixed;
}

.container {
  @include baseStyle;
  animation: slideIn $complex-duration ease;

  top: 0;
  z-index: $sticky-panel-z-index;

  .headerSearchBar {
    background-color: $med-light-grey;
  }
}

.docked {
  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media #{$medium-up} and #{$tall-screen-min} {
    display: block;
    background: white;
    position: relative;
  }

  .headerSearchBar {
    background-color: $drk-light-grey;
  }
}

.navigationBar {
  display: none;
  @media #{$medium-up} and #{$tall-screen-min} {
    display: flex;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  }
}

@keyframes slideIn {
  from { top: -203px; }

  to { top: 0; }
}
