@import "app/styles/getty/Legacy.module.scss";

.container {
  display: flex;
  flex-direction: row;
  margin: 0 0 .5em 0;

  padding: 0;

  @media #{$medium-up} {
    padding: 0 2.25rem;
  }
}

.relatedItemsContainer {
  overflow: hidden;
}

.containerBottom {
  display: flex;
  flex-direction: row;

  padding: 0;

  @media #{$medium-up} {
    padding: 0 2.25rem 32px 2.25rem;
  }
}
