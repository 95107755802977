@use "sass:math";
@import "app/styles/getty/Legacy.module.scss";

$onoffswitch_size: 20px;
$onoffswitch_border: 1px;

.disabled {
  opacity: .4;
  pointer-events: none;
  color: #a6a6a6;
}

.grouped {
  padding: 0 !important;
  margin-top: -10px;
  margin-bottom: 24px;
}

.sliderWrapper {
  padding: {
    top: 20px;
    bottom: 5px;
  }

  display: flex;
  align-items: center;
}

.slider {
  width: $onoffswitch_size * 2 + $onoffswitch_border;
  min-width: $onoffswitch_size * 2 + $onoffswitch_border;

  position: relative;
  display: inline-block;
  
  user-select: none;
}

.sliderText {
  font-size: 0.875rem;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle;
  text-align: left;
}

.sliderSubtitle {
  color: #666;
  flex-direction: row;
  font-size: 12px;
  margin-top: 5px;
}

.sliderLabel {
  padding: 0px;

  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: $onoffswitch_size;
  border: $onoffswitch_border solid $grey-50;

  .selected & {
    border-color: $grey-70;
  }
}

.sliderInner {
  width: 200%;
  margin-left: -100%;
  
  transition: margin $complex-duration ease;

  .selected & {
    margin-left: 0;
  }

  &:before, &:after {
    content: "";

    width: 50%;
    height: $onoffswitch_size + $onoffswitch_border;

    float: left;
    background-color: $gi-white;

    .selected & {
      background-color: $grey-70;
    }
  }
  &:before {
    padding-left: math.div($onoffswitch_size, 2);
    background-image: url(~images/checkmark.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  &:after {
    padding-right: math.div($onoffswitch_size, 2);
  }
}

.sliderSwitch {
  position: absolute;
  right: $onoffswitch_size - ($onoffswitch_border);
  top: $onoffswitch_border;
  bottom: 0;
  width: $onoffswitch_size - $onoffswitch_border;
  height: $onoffswitch_size - $onoffswitch_border;
  margin: $onoffswitch_border;

  display: block;
  background: $gi-white;
  border: $onoffswitch_border solid $grey-50;
  border-radius: $onoffswitch_size;
  transition: all $complex-duration ease;

  .selected & {
    right: $onoffswitch_border;
    border-color: $grey-70;
  }
}