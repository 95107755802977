@import 'app/styles/getty/Legacy.module.scss';
@import 'app/styles/getty/Mixins.module.scss';

.container {
  margin: 10px 0 15px 0;
  display: flex;
  flex-basis: 100%;
  justify-content: end;
  div {
    cursor: pointer;
  }

  @media #{$medium-up} {
    justify-content: unset;
  }
}

%toggleCount {
  color: $gi-black;
  &:hover,
  &:focus {
    text-decoration: none;
    color: $confirmed-color;
  }
}

.toggleImages {
  padding-right: 10px;
  border-right: 1px solid $grey-40;
  > div {
    @extend %toggleCount;
  }
}

.toggleEvents {
  padding-left: 10px;
  padding-right: 10px;
  > div {
    @extend %toggleCount;
  }
}

.toggleVideos {
  padding-left: 10px;
  border-left: 1px solid $grey-40;
  > div {
    @extend %toggleCount;
  }
}

.disabled {
  color: $grey-50;
  cursor: default;

  &:hover {
    color: $gi-black;
  }
}

.selected {
  &:after {
    opacity: 1;
  }
}

.emphasis {
  @extend .action--with-emphasis;
  @include buttonLink;
}
