@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints.scss';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.content {
  justify-self: flex-start;
  font-size: 14px;
  margin: 0;

  padding: 0 1rem 0 1rem;

  @media #{$medium-up} {
    padding: 0 2.25rem;
  }

  &Top {
    @media #{$medium-up} {
      padding: 0 1.5rem;
    }
    @media #{$large-up} {
      padding: 0 2.25rem;
    }
    margin: 0 0 1rem 0;
  }
}

.content a {
  color: $primary-color;
}

.content a:hover {
  color: $primary-color-highlight;
  text-decoration: none;
}