@import "app/styles/getty/Legacy.module.scss";

.container {
  padding: 0 1rem 0 1rem;

  @media #{$medium-up} {
    padding: 0 2.25em;
  }
}

.headline {
  display: block;
  margin: 0 1rem 0 0;

  font-size: 21px;
  line-height: 25px;
}

.byline {
  font-size: 1rem;
  text-transform: capitalize;
  margin-top: 8px;
}
