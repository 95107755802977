.priorDownloadContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 1.5em;
}

%prior-download {
  display: inline-block;
  background: white;
  border: 1px solid $grey-40;
  padding: {
    left: .5rem;
    right: .5rem;
    top: .25rem;
    bottom: .25rem;
  }
  font-size: .75rem;
  line-height: 18px;

  animation: prior-download__fade-in $complex-duration ease forwards;
}

@keyframes prior-download__fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}
