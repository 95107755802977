@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";

.container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $clip-badge-z-index;
  color: $gi-white;
  cursor: pointer;
  border-radius: 3px;
  line-height: 18px;
  padding: 0 5px;
  height: 28px;
}

.icon {
  display: block;
  margin-left: 6px;
  align-self: center;
  height: 20px;
  width: 28px;
}

.clipLength {
  display: flex;
  align-items: center;
  font-size: rem(14);
  font-weight: 600;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
