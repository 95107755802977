@import "app/styles/getty/Legacy.module.scss";

.fixedEventContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em .5em;
}

.captionContainer {
  flex: 1;
  margin-bottom: 2.5rem;
  font-size: .875rem;
  color: $gi-black;
  &Date {
    margin-bottom: 1rem;
    color: $grey-70;
  }
}
 
.countsContainer {
  display:flex;
  flex-direction: column;
  color: $grey-70;
  font-size: .875rem;
  line-height: 1.4;

  &:hover {
    color: $confirmed-color;
    cursor: pointer;
  }

  > svg {
    margin-right: .5rem;
  }
}
