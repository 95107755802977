@import 'app/styles/getty/Legacy.module.scss';
@import 'app/styles/getty/CheckboxField.module.scss';
@import 'app/styles/getty/Zindex.module.scss';
@import 'app/styles/getty/PriorDownload.module.scss';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';

@mixin baseGalleryMosaicAsset {
  margin-right: 0.75em;
  margin-bottom: 0.75em;
  flex-grow: calc(var(--ratio) * 100);
  --ratio: calc(var(--width) / var(--height));
  --row-height: 12em;
  flex-basis: calc(var(--ratio) * var(--row-height));
  position: relative;

  @media #{$ie11-media-feature} {
    display: flex;
    flex-basis: auto;
    min-height: auto;
    margin: auto;
    padding: 0.3em;
  }

  @media #{$xlarge-up} {
    --row-height: 16em;
  }

  @media #{$xxlarge-up} {
    --row-height: 18em;
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;

    .link {
      display: block;
      height: 100%;
      width: 100%;
      cursor: pointer;

      .figure {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $gi-white;

        &Archive {
          background-color: $grey-70;
        }

        &PlayVideo {
          &:after {
            .container:hover & {
              opacity: 0;
            }
          }
        }

        .backgroundOverlay {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba($gi-black, 0.2);
          opacity: 0;
          transition: opacity $complex-duration ease;
          pointer-events: none;
        }

        .thumb {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-width: 100%;

          transition: opacity $complex-duration ease;

          &:not(.thumbArchive) {
            object-fit: cover;
          }

          &Archive {
            display: block;
            margin: {
              right: auto;
              left: auto;
            }
          }

          &_BBA {
            width: 60%;
          }

          &_ITNA {
            width: 17%;
            padding: 50px 0 20px 0;
          }

          &_NNBA {
            width: 60%;
          }
        }

        figcaption {
          display: none;
        }
      }
    }

    .batchDownloadCheckbox {
      display: none;
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: $action-icon-overlay-z-index;

      input {
        background-color: $gi-white;
        cursor: pointer;
      }

      &Visible {
        display: block;
      }
    }

    .inBoard {
      position: absolute;
      right: 1em;
      bottom: 1em;
      visibility: visible;
      opacity: 1;
      transition: all $complex-duration ease;
    }

    .btn {
      display: flex;
      align-items: center;
      height: 39px;
      margin-top: 0.5em;
      color: $gi-white;
      background-color: rgba($gi-black, 0.6);
      border: 1px solid rgba($grey-70, 0.4);
      border-radius: 5px;
      cursor: pointer;
      transition: all $complex-duration ease;

      &:hover {
        color: $gi-black;
        background-color: $gi-white;
        text-decoration: none;
      }
    }

    .iconContainer {
      justify-content: center;
      min-width: 39px;
      width: auto;
      padding: 0 0.5em;

      &:not(:last-child) {
        margin-right: 0.5em;
      }

      span {
        padding-left: 6px;
        font-size: 0.875em;
      }
    }
  }
}

.showGalleryAssetTitle {
  margin-bottom: 2.5em !important;
}

.assetTitle {
  position: absolute;
  padding: 10px 0 10px 10px;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  a {
    color: #353838;
    font-weight: 400;
    text-decoration: none;
  }
}

.galleryMosaicAsset {
  @include baseGalleryMosaicAsset;
}

.clipBadge {
  margin: 10px;
}

.galleryMosaicPanoHoriz {
  @include baseGalleryMosaicAsset;
  @media #{$small-up} {
    --row-height: 10em;
  }
  --row-height: 8em;
}

.galleryMosaicVideo {
  @include baseGalleryMosaicAsset;
  --row-height: 10em;

  .editorialInfo {
    line-height: 1.4;
    position: absolute;
    top: 1em;
    left: 1em;
    right: 1em;
    color: $gi-white;
  }

  .editorialText,
  .editorialPeople,
  .dateCreated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .editorialPeople,
  .dateCreated {
    font-size: 12px;
  }

  @media #{$medium-up} {
    --row-height: 12em;
  }

  @media #{$xxlarge-up} {
    --row-height: 15em;
  }
}

.galleryMosaicOfflinecontent {
  @include baseGalleryMosaicAsset;
  min-height: 160px;
}

.checkbox {
  @extend .checkbox-field__input;
}

.priorDownloadContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 1.5em;

  .priorDownload {
    display: inline-block;
    background: white;
    border: 1px solid $grey-40;
    padding: {
      left: 0.5rem;
      right: 0.5rem;
      top: 0.25rem;
      bottom: 0.25rem;
    }
    font-size: 0.75rem;
    line-height: 18px;

    animation: prior-download__fade-in $complex-duration ease forwards;

    margin: {
      left: 3.5rem;
      top: 1rem;
    }
  }
}

@keyframes prior-download__fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.galleryMosaicAsset:hover .container .inBoard {
  visibility: hidden;
  opacity: 0;
}

.galleryMosaicAsset:hover .container .batchDownloadCheckbox {
  display: block;
}

.galleryMosaicAsset:hover .container .figure .backgroundOverlay {
  opacity: 1;
}
