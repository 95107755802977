@import "app/styles/getty/Legacy.module.scss";

.container {
  margin: 1rem 0;
}

.relatedItemsContainer {
  display: flex;
  flex-direction: row;
  margin: 0 0 .5em 0;

  padding: 0;

  @media #{$medium-up} {
    padding: 0 2.25rem;
  }
}

.noticeContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-basis: 100%;
  min-width: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 1rem;

  @media #{$medium-up} {
    padding: 0 2.25rem;
  }
}

.breadcrumbs {
  padding: 0 1rem 1rem 1rem;

  @media #{$medium-up} {
    padding: 0 2.25rem 1rem 2.25rem;
  }
}

.link {
  all: unset;
  
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: baseline;
  color: $purple;
  font-size: 0.875rem;
  &:hover {
    color: $purple-darker;
  }

  .icon {
    margin-right: 8px;
    fill: $purple;
  }

  a {
    all: unset;
    &:hover {
      text-decoration: none;
    }
  }
}
