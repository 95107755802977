@import "app/styles/getty/Legacy.module.scss";

.header {
  flex-direction: column;
  align-items: flex-start;
}

.body {
  overflow-y: hidden;
  width: 600px;
  padding: 0 2rem 1.5rem;
  margin-bottom: 1rem;
  height: 65vh;
  max-height: 700px;
}

.searchBox {
  position: relative;

  width: 535px;
  min-height: 48px;
  height: 48px;
  margin: 1rem 0;
  left: 30px;
  border: 1px solid $grey-50;

  &:active {
    border-color: $gi-black;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 1rem;

  color: $gi-black;
  cursor: pointer;
  transform: translateY(-50%);
}

.input {
  height: 100%;
  width: 100%;
  padding-left: 3rem;
  
  font-size: 1rem;
  border: 0;
  
  &:focus {
    outline: none;
  }
}