@import "app/styles/getty/Legacy.module.scss";

@mixin buttonLink {
  background-color: inherit;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
