.count {
  display: flex;
  overflow: hidden;
}

.icon {
  margin: 0 .5rem;
  vertical-align: bottom;
  height: 100%
}
