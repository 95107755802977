.controlContainer{
  display: block;
}

.selectBox {
  border: 1px solid #a6a6a6;
  border-radius: 0;
  cursor: pointer;  
  min-height: 59px;
  padding: .4em 1.2em;
  box-shadow: none;
  margin-top: -15px;
  &:hover {
    border-color: #080808;
  }
}

.activeControl {
  border-color: #080808;
}

.agreementTitle {
  font-size: 14px;
}

.subscript {
  color: #666;
  font-size: 12px;
  padding-top: .3em;
}

.menu {
  padding: 0;
  border-radius: 0;
  border: 1px solid black;
  margin-top: 0;
  border-top: none;
  > div {
    padding: 0;
  }
}

.options {
  padding: .6em 1.2em;
  cursor: pointer;
  background-color: inherit;
  color: #080808;
  &:hover {
    background-color: #f4f4f4;
  }
}

.selectedOption {
  background-color: #f4f4f4;
  > div > div:first-child {
    font-weight: 700;
  }
}

.disabledOption {
  opacity: .4;
  pointer-events: none;
}
