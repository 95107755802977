@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";

.dropdown {
  padding: 8px 28px 8px 16px;
  height: 36px;
  width: 100%;
  margin-bottom: 10px;

  border: 1px solid $grey-50;
  border-radius: 0px;
  overflow: hidden;
  background-color: $gi-white;
  font-size: 0.875rem;
  background-image: url("~images/dropdown.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
  appearance: none;

  &:focus,
  &:hover {
    border-color: $gi-black;
    outline: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $gi-black;
  }

  &::-ms-expand {
    display: none;
  }
}

.daterangeContainer {
  position: relative;
}

.datepickerLabel {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 1.4rem;
  font-size: 12px;
  padding-left: 0.75rem;

  padding-right: 0.5em;
  border: none;
  border-right: 1px solid #a6a6a6;
  height: 12px;

  line-height: 12px;
  color: #666;
  flex: 1 1;
}

.datepickerFromLabel {
  width: 47px;
}

.datepickerToLabel {
  width: 40px;
}

.datepicker {
  font-size: 12px;
  width: 100%;
  > div {
    border: 1px solid #a6a6a6;
    background-color: #fff;
    height: 36px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    > div {
      padding-left: 40px;
      input {
        outline: none;
      }
    }
  }

  span {
    // Calendar Container
    > div:first-child {
      z-index: 2;
    }
  }
}

.calendarSubContainer {
  max-width: 55%;
  > div {
    // Navigation
    &:first-child {
      height: 28px;
      margin-bottom: 0;
      button {
        &:last-child {
          transform: rotateY(180deg);
        }
      }
    }
  }

  // ViewContainer
  > div + div {
    > div > div > div > div {
      // Weekdays
      &:first-child {
        text-transform: none;
        text-align: center;
        color: #a6a6a6;
        font-size: 10px;
      }

      abbr {
        text-decoration: none;
      }
    }
  }
}

.inactiveTile {
  color: rgb(8, 8, 8);
  border: 1px solid transparent !important;
  border-radius: 0;
  font-size: 12px;
  font-weight: 400;
  padding: 0.2rem !important;
  background-color: #fff;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: inherit !important;
    border-color: rgb(8, 8, 8) !important;
  }
}

.activeTile {
  @extend .inactiveTile;
  background: #666;
  color: #fff;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: rgb(8, 8, 8) !important;
  }
}
