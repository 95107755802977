@import "app/styles/getty/Legacy.module.scss";

.searchContentMosaic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0.25rem 0 1rem;

  @media #{$medium-up} {
    padding: {
      right: 0.75rem;
      left: 1.5rem;
    }
  }

  @media #{$large-up} {
    padding: {
      right: 1.5rem;
      left: 2.25rem;
    }
  }
}

.searchContentMosaic::after {
  --width: 2;
  --height: 1;
  --ratio: calc(var(--width) / var(--height));
  --row-height: 12em;
  flex-basis: calc(var(--ratio) * var(--row-height));
  content: '';
  flex-grow: 1000000;
  @media #{$large-up} {
    --row-height: 14em;
  }
}

// Fixed grid should always align to the left
.searchContentFixed {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(194px, max-content));
  grid-gap: 0px;
  padding: 0 0.25rem 0 1rem;

  @media #{$medium-up} {
    padding: {
      right: 0.75rem;
      left: 1.5rem;
    }
  }

  @media #{$large-up} {
    padding: {
      right: 1.5rem;
      left: 2.25rem;
    }
  }
}
