@import "app/styles/getty/Legacy.module.scss";

.editorialAssetPivotLink {
  display: flex;
  align-items: center;
  color: $primary-color;
  text-decoration: none;
  font-size: .875rem;
  padding: 0;

  .forEvent {
    display: none;
  }

  &:hover, 
  &:focus {
    text-decoration: none;
  }

  span {
    margin-left: 6px;
  }
}

.event {
  border-left: 1px solid $grey-40;
  padding: 0 .875rem;

  .forEvent {
    display: inline-block;
  }
}