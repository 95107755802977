@import "app/styles/getty/Legacy.module.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 2rem 0.25rem 1rem 1rem;

  @media #{$medium-up} {
    padding: {
      right: 0.75rem;
      left: 1.5rem;
    }
  }

  @media #{$large-up} {
    padding: 1.5rem 1.5rem 1.5rem 2.25rem;
  }
}

.header {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.result {
  &:hover {
    cursor: pointer;
  }
}
