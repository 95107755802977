@use '~@unisporkal/alliance-ui-theme/dist/styles/common/_index.scss' as
  commonStyles;
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.tooltip {
  bottom: -40px;
  left: -10px;
  border: 1px solid $grey-50;
  padding: 9px 16px;
  width: 93%;
}

.newBadge {
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  background-color: commonStyles.$crimson;
  color: white;
  margin-right: 3px;
}