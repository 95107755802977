@import "app/styles/getty/Legacy.module.scss";

.body {
  overflow-y: hidden;
  width: 600px;
  padding: 0 2rem 0;
  height: 65vh;
  max-height: 700px;
}

.header {
  flex-direction: column;
  align-items: flex-start;
}