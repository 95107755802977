@import "app/styles/getty/Legacy.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints";

.bannerContainer {
  align-items: center;
  background: radial-gradient(at right top, #c15c84, #761534, #2a0e42);
  background-size: cover;
  background-repeat: no-repeat;
  color: $gi-white;
  height: 350px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  // Mobile
  @media screen and (max-width: $small-media-breakpoint-end) {
    display: none;
  }

  @media screen and (max-width: $medium-media-breakpoint-end) {
    flex-direction: column;
    padding: 32px;
  }
}

.leftText{
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 2.3rem;
  line-height: normal;
  padding-right: 7%;

  @media screen and (max-width: $medium-media-breakpoint-end) {
    align-items: center;
    padding-right: 0;
    padding-bottom: 30px;
  }
}

.subHeaderText {
  font-weight: 700;
  padding-top: 16px;
}

.bannerContainer .bannerCta {
  background-color: rgba($gi-black, 0.5) !important; // overriding alliance ui styles

  &:hover {
    background-color: $gi-white !important; // overriding alliance ui styles

    .bannerIcon {
      path {
        fill: $gi-black;
      }
    }
  }
}

.rightButton {
  min-width: max-content;
}

.bannerCta {
  color: $gi-white;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $gi-black;
}

.bannerIcon {
  height: 28px;
  padding-right: 8px;
  path {
    fill: $gi-white;
  }
}
