@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Variables.module.scss";

.simpleContainer {
  width: 100%;
  position: relative;
  min-height: 40rem;
  background-color: $grey-20;
  overflow: hidden;
}

.container {
  width: 100%;
  position: relative;
  min-height: 40rem;
  background-color: $grey-20;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

.rowContainer {
  display: flex;
  flex-direction: row;
}

.searchBarRow {
  height: 59px;
  @media #{$medium-up} {
    height: 93px;
  }
}

.columnPanelContainerScrollUp {
  @media #{$large-up} {
    top: 203px;
  }
}

.appliedFiltersContainer {
  padding-right: 0;
  padding-left: 0;
  padding-top: .5rem;
  padding-bottom: 0;
  &::after {
    content: "";
    display: block;
    border-bottom: 1px solid $drk-light-grey;
    padding-top: 1.125rem;
  }
}

.appliedFilterPill {
  height: 36px;
  font-size: 0.875rem;
  padding-left: .5rem;
  padding-right: .5rem;
  svg {
    color: $med-grey;
  }
  &:hover {
    border: 1px solid $black;
    svg {
      color: $black;
    }
  }
}

.appliedFilterClearAll {
  font-size: .875rem;
  color: $primary-color;
  margin-bottom: 0;
  &:hover {
    color: $purple-darker;
  }
}

