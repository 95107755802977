@import 'app/styles/getty/Legacy.module.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  margin: 1rem 2.25rem;
  padding: 0.75rem 1rem;
  background-color: $alert-background-color;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  align-items: center;
  font-size: 14px;

  svg {
    flex-shrink: 0;
  }

  .icon {
    padding-right: 3px;
  }
}

.languageContainer {
  @extend .container;
  padding-right: 20px;
}

.content {
  display: inline-block;
  background: none;
  border: none;

  span {
    span {
      font-weight: 700;
    }
  }

  .link {
    color: $confirmed-color;
    font-weight: 700;
    cursor: pointer;
  }

  &:nth-child(even) {
    margin-left: 10px;
  }
}

.container.applied {
  .link {
    color: $confirmed-color;
    font-weight: normal;
    cursor: pointer;
    text-align: left;
  }
}

.container.suggested {
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
  padding: 0rem;

  .icon {
    path {
      fill: $confirmed-color;
    }
  }
}
