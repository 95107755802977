@import "app/styles/getty/Legacy.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.tileContainer {
  align-items: center;
  background: radial-gradient(at right top,#c15c84,#761534,#2a0e42);
  background-size: cover;
  background-repeat: no-repeat;
  color: $gi-white;
  padding: 24px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-height: 310px;
  width: 472px;

  // Mobile
  @media #{'screen and (max-width: 540px)'} {
    display: none;
  }

  // Tablet
  @media #{'screen and (max-width: 800px)'} {
    flex-direction: column;
    padding: 32px;
    width: 100%;
  }

  &:hover {
    // slightly darken background on hover
    background: radial-gradient(at right top, #934765, #580f26, #12061d);
    cursor: pointer;
    color: $gi-white;
    text-decoration: none;

    .tileButton {
      background-color: $gi-white;
      color: $gi-black;

      .tileIcon {
        path {
          fill: $gi-black;
        }
      }
    }
  }
}

.topText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  font-size: 1.1rem;
  line-height: normal;
}

.subHeaderText {
  padding-top: 16px;
  font-size: 0.85rem;
  font-weight: normal;
}

.tileContainer .tileButton {
  background-color: rgba($gi-black, 0.5);

  &:hover {
    background-color: $gi-white;

    .tileIcon {
      path {
        fill: $gi-black;
      }
    }
  }
}

.tileButton {
  color: $gi-white;
  display: flex;
  text-decoration: none;
  border-radius: 7px;
  padding: 10px 15px;
  cursor: pointer;
  max-width: 100%;
}

.tileIcon {
  height: 20px;
  padding-right: 8px;
  path {
    fill: $gi-white;
  }
}
