@import "app/styles/getty/Legacy.module.scss";

%eventOverlay {
  position: absolute;
  z-index: $editorial-image-overlay-z-index;

  color: $gi-white;
}

.captionContainer {
  @extend %eventOverlay;
  bottom: .5rem;
  max-height: calc(100% - .5rem);
  max-width: 100%;
  overflow: hidden;
  
  padding: 0 .5rem;
  
  font-size: 1rem;
  line-height: 1.4;

  &:hover {
    text-decoration: underline;
  }

  @media #{$medium-up} {
    bottom: 1rem;
    padding: 0 1rem;
  }
}

.eventCaption {
  font-size: .875rem;
  bottom: 0;

  @media #{$large-up} {
    font-size: 1rem;
  }
}

.eventDate {
  font-size: .625rem;

  @media #{$medium-up} {
    font-size: .75rem;
  }
}

.countContainer {
  @extend %eventOverlay;
  top: .75rem;
  right: .75rem;
  display: flex;

  padding: .25rem .5rem;
  border-radius: 5px;
  
  font-size: .75rem;

  background-color: rgba($gi-black, 0.4);
}