@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support";

.backgroundGradient {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 80.65%;

  background: linear-gradient(180deg, rgba(8, 8, 8, 0.3) 0%, rgba(8, 8, 8, 0) 100%);
}

.licenseBadge {
  flex-shrink: 0;
  width: 27px;
  height: 27px;
  margin-right: .5em;
}

.editorialUse {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;

  font-size: 0.75em;
  color: $gi-black;
  background-color: $warning-color;
  text-align: center;
}

.editorialInfo {
  min-width: 0;
  line-height: 1.4;

  &--archive {
    position: absolute;
    top: 1em;
    left: 1em;
    right: 1em;

    color: $gi-white;
  }
}

.editorialText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

  @media #{$ie11-media-feature} {
    white-space: normal;
  }
}

.editorialPeople,
.dateCreated {
  @extend .editorialText;
  font-size: 0.75em;
}

%assetButton {
  display: flex;
  align-items: center;
  height: 39px;
  margin-top: 0.5em;

  color: $gi-white;
  background-color: rgba($gi-black, 0.6);
  border: 1px solid rgba($grey-70, 0.4);
  border-radius: 5px;
  cursor: pointer;
  transition: all $complex-duration ease;

  &:hover {
    color: $gi-black;
    background-color: $gi-white;
    text-decoration: none;
  }

  &--boards {
    transition-duration: 0s;

    &:hover {
      transition-duration: $complex-duration;
    }

    svg {
      height: auto;
    }
  }

  &#gix-activeness {
    color: $gi-black;
    background-color: $gi-white;
  }
}

.similarButton {
  @extend %assetButton;
  padding: {
    right: 1rem;
    left: 1rem;
  }

  font-size: 0.875em;
}

%actions {
  position: absolute;
  left: 1em;
  right: 1em;
  z-index: $action-icon-overlay-z-index;

  transition: opacity $complex-duration ease;

  opacity: 1;
}

.actionsTop {
  @extend %actions;
  display: none;
  @media #{$medium-up} {
    display: flex;
  }
  top: 1em;
  left: 1em;
  align-items: stretch;

  color: $gi-white;
  transform: translate3d(0, 0, 0);
  transition: transform $complex-duration ease;
  cursor: pointer;
}

.actionsBottom {
  @extend %actions;
  display: none;

  @media #{$medium-up} {
    display: flex;
  }

  bottom: 1em;
  flex-wrap: wrap;
  justify-content: space-between;
  transform: translate3d(0, 4px0, 0);
  transition: transform $complex-duration ease;

  &:hover  {
    transform: translate3d(0, 0, 0);
  }
}

.actionsRight {
  display: flex;
  margin-top: 0.5em;
}
